import * as React from "react";
import {
  Container,
  Banner,
  Mission,
  Work,
  Service,
  Recruit,
  News,
  Breadcrumb,
  Contact,
} from "../components";
import Seo from "../components/Seo";

const IndexPage = () => {
  return (
    <Container>
      <Seo title="システム開発・Web制作" />
      <Banner />
      <Mission />
      <Work />
      <Service />
      <Recruit />
      <News />
      <Breadcrumb />
      <Contact />
    </Container>
  );
};

export default IndexPage;
